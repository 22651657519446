<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">

  <div id="page-content"  v-if="!viLoader">
          <span style="text-transform: uppercase;font-weight: bold;font-size: 1.78rem;">Enroll School</span>
          <span style="margin-left: 12%;font-weight: bold" v-if="count.isVerified">Your School is on Free Plan. Please upgrade to paid plan to unlock all features</span>
          <span style="margin-left: 12%;font-weight: bold" v-else>Your School is on Free Plan.</span>

          <div class="row mt-4 countsection">
            <div class="col-lg-12">
              <div class="countsect p-2 ml-2 mr-2">
                <ul class="dispflex" >
                  <li>
                     <span :class="count.isVerified ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;"  :class="count.isVerified ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i></span
                    >Vidhyaan Approval
                  </li>
                   <li>


                    <span :class="count.isVerified ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;"  :class="count.isVerified ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i></span
                    >Setup Your School 
                  </li>
                  
                   <li>
                   <span :class="count.isVerified ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;"  :class="count.isVerified ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i></span
                    >Preview your School
                  </li>
                  <li>
                    <span :class="count.onlineAdmission > 0 ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;"  :class="count.onlineAdmission > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i></span
                    >Enable Online Admission
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row mt-2" :style="!count.isVerified ? 'cursor: not-allowed' : '' ">
            <div class="col-md-12 col-lg-12 col-sm-12" :style="!count.isVerified ? 'cursor: not-allowed' : '' ">
              <div class="widjet p-3 m-2">
                <div class="row row-in">
                 
                  <div class="col-lg-6 col-sm-6 row-in-br" :style="!count.isVerified ? 'cursor: not-allowed' : '' ">
                    <div :style="!count.isVerified ? 'cursor: not-allowed' : '' "
                      class=" row setupsect  my-1" 
                       @click.prevent="redirectRoute('/admission/school/profile')"
                    >
                      <div class="col-auto" style="padding-right:0">
                        <div class="iconbg bg-light-green">
                          <i class="fas fa-school text-green"></i>
                        </div>
                      </div>
                      <div class="col">
                        <h5 class="text-muted mt-2" :style="!count.isVerified ? 'cursor: not-allowed' : '' ">
                          SetUp Your School
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 row-in-br" :style="!count.isVerified ? 'cursor: not-allowed' : '' ">
                    <div
                      class=" row setupsect my-1"
                      @click.prevent="redirectRouteToViewSchool"
                      :style="!count.isVerified ? 'cursor: not-allowed' : '' "
                    >
                    <!-- @click.prevent="redirectRouteToViewSchool" -->
                      <div class="col-auto" style="padding-right:0">
                        <div class="iconbg bg-light-magenta">
                          <i class="fas fa-user-cog text-magenta"></i>
                        </div>
                      </div>
                      <div class="col">
                        <h5 class="text-muted mt-2" :style="!count.isVerified ? 'cursor: not-allowed' : '' " @click.prevent="redirectRouteToViewSchool">
                          
                          Preview your School in Vidhyaan
                        </h5>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-4" :style="!count.isVerified ? 'cursor: not-allowed' : '' ">
              <div
                class="white-box m-2 white-box1"
               
                :style="count.isVerified ? 'padding: 0' : 'padding: 0;pointer-events: none;cursor: not-allowed' "
                  @click.prevent="redirectRoute('/admission/academicYear')"
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 1</span>
                  <span class="admcount org_bg">{{ count.academicYear }}</span
                  ><span style="display: block;line-height:18px;">Create Academic Year</span>
                </div>
                <div class="admcount_rgt">
                  <div class="triangle-left"></div>
                  Create Academic Year
                  <span><br />[for eg: 2021 - 2022,2022 - 2023]</span>
                </div>
              </div>
            </div>
            <div class="col-md-4" :style="!count.isVerified ? 'cursor: not-allowed' : '' " >
              <div
                class="white-box m-2 white-box1"
                :style="count.isVerified ? 'padding: 0' : 'padding: 0;pointer-events: none;cursor: not-allowed' "
                 @click.prevent="redirectRoute('/enroll/schoolType')"
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 2</span>
                  <span class="admcount org_bg">{{ count.class }}</span
                  ><span  style="display: block;line-height:18px;">Setup School Type</span>
                </div>
                <div class="admcount_rgt">
                  <div class="triangle-left"></div>
                  Setup School Type <br /><span
                    >[For eg. LKG, UKG, Ist Std, IInd Std etc]</span
                  >
                </div>
              </div>
            </div>
             <div class="col-md-4" :style="!count.isVerified ? 'cursor: not-allowed' : '' ">
              <div
                class="white-box m-2 white-box1"
                :style="count.isVerified ? 'padding: 0' : 'padding: 0;pointer-events: none;cursor: not-allowed' "
                 @click.prevent="redirectRoute('/enroll/class')"
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 3</span>
                  <span class="admcount org_bg">{{ count.class }}</span
                  ><span  style="display: block;line-height:18px;">Manage Class</span>
                </div>
                <div class="admcount_rgt">
                  <div class="triangle-left"></div>
                  Manage your Class as per your Standards <br /><span
                    >[For eg. LKG, UKG, Ist Std, IInd Std etc]</span
                  >
                </div>
              </div>
            </div>
            <!-- <div class="col-md-4" :style="!count.isVerified ? 'cursor: not-allowed' : '' ">
              <div
                style="padding: 0;cursor: not-allowed;"
                class="white-box m-2 white-box1"

                 
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 4</span>
                  <span class="admcount org_bg">{{ count.admission }}</span
                  ><span style="display: block;line-height:18px;">Manage Admissions</span>
                </div>
                <div class="admcount_rgt">
                  <div class="triangle-left"></div>
                  Manage Admissions process for the current year
                  <span>[the current annual period <br />2021 - 20]</span>
                </div>
              </div>
            </div>
            <div class="col-md-4" >
              <div
                class="white-box m-2 white-box1"
                style="padding: 0;cursor: not-allowed;"
                
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 5</span>
                  <span class="admcount org_bg">{{ count.onlineAdmission }}</span
                  ><span  style="display: block;line-height:18px;">Online Admission Leads</span>
                </div>
                <div class="admcount_rgt">
                  <div class="triangle-left"></div>
                  Capture your admission online through vidhyaan website portal
                  <span>Manage Admission Leads through vidhyaan website</span>
                </div>
              </div>
            </div> -->
            
            
          </div>
         
  </div>
  <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
  </div>

</template>
<script>
import secureUI from "../../utils/secureUI";
import ViService from "@/services/ViService";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "enrollSchool",

  data() {
    return {
      count: {
        academicYear: 0,
        admission: 0,
        class: 0,
        onlineAdmission: 0,
        isVerified: false
      },
      viLoader: false
      
    };
  },
  created() {
     this.getAllObjectCounts();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },
  methods: {
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    async getAllObjectCounts() {
          let userData = secureUI.sessionGet("user");
          
          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push('/login');
          } else {
            this.viLoader = true;
            const response = await ViService.viXGet(
              "/admission/enroll/getAllObjectCounts",
              userData.token
            );
            if (response.isSuccess) {
            
              const objResult = secureUI.secureGet(response.data);

              this.count.academicYear = objResult.academicYear ? objResult.academicYear : 0
              this.count.admission = objResult.admission ? objResult.admission : 0
              this.count.class = objResult.class ? objResult.class : 0
              this.count.onlineAdmission = objResult.onlineAdmission ? objResult.onlineAdmission : 0
              this.count.isVerified = objResult.isVerified

              this.$toasted.success(response.message);
            } else {
              this.$toasted.error(response.message);
               if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
            }
            this.viLoader = false;
          }
        },
    redirectRouteToViewSchool(){

          let userData = secureUI.sessionGet("user");
          
          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push('/login');
          } else {
            window.open(`https://app--stage.vidhyaan.com/search-school/overview/?schoolName=${userData.userDetails.account.accountName}&accId=${userData.userDetails.account.accountId}`);

          }

    },
  },
  components: {
    ViSpinner
  }
};
</script>
