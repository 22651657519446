<template>
  
  <div > 
  <h1> Thank You for Subscribing! </h1>
  <p>If you have any questions or concerns regarding this, do not hesitate to contact us at adminuser@vidhyaan.com</p>
  </div>

</template>
<script>

import secureUI from "../utils/secureUI";
import ViService from "@/services/ViService";

export default {
  name: "vi-payment-successfully",
  
  data() {
    return {
      
    };
  },
  created() {
	  this.enrollPlanCompleted();
  },
  methods: {
   
    async enrollPlanCompleted() {       

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
         const response = await ViService.viXGet('/zohoApiCall/updateEnrollSchoolPlan',userData.token );
        if (response.isSuccess) {
           if(userData){
            setTimeout(() => {
                this.$router.push('/login');
                //location.reload();
              }, 10000);
           }else{
            setTimeout(() => {
                this.$router.push('/login');
              }, 10000);
           }

           

          //this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
        }
      }
    
       
       }}
};
</script>

<style lang="scss" scoped></style>